<template>
    <div class="management-content">
        <table class="management-table">
            <thead>
            <td colspan="3" class="management-content-title">{{$t('driver.driverInformation')}}</td>
            </thead>
            <tr>
                <td>
                    <div class="text-prop">{{$t('driver.driverName')}}</div>
                    <div v-if="!currentDriver" class="text-value"></div>
                    <div v-else class="text-value text-max-width">{{currentDriver.FirstName}}
                        {{currentDriver.LastName}}
                    </div>
                </td>
                <td>
                    <div class="text-prop text-prop-max-width">{{$t('driver.contactNumber')}}</div>
                    <div class="text-value">{{currentDriver ? (currentDriver.CellPhone ? currentDriver.CellPhone :
                        $t('globalText.none')): ""}}
                    </div>
                </td>
                <td>
                    <div class="text-prop">{{$t('driver.dateJoined')}}</div>
                    <div class="text-value">{{currentDriver ? (currentDriver.DateJoined ?
                        formatDate(currentDriver.DateJoined): $t('globalText.none')): ""}}
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="text-prop">{{$t('driver.emergContact')}}</div>
                    <div class="text-value">{{currentDriver ? (currentDriver.Contact ? currentDriver.Contact : $t('globalText.none')):
                        ""}}
                    </div>
                </td>
                <td>
                    <div class="text-prop text-prop-max-width">{{$t('driver.emergContactNumber')}}</div>
                    <div class="text-value">{{currentDriver ? (currentDriver.ContactCellPhone ?
                        currentDriver.ContactCellPhone : $t('globalText.none')): ""}}
                    </div>
                </td>
                <td>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="text-prop">{{$t('driver.licenseNumber')}}</div>
                    <div class="text-value">{{currentDriver ? (currentDriver.License ? currentDriver.License : $t('globalText.none')):
                        ""}}
                    </div>
                </td>
                <td>
                    <div class="text-prop  text-prop-max-width">{{$t('driver.faceIDPhoto')}}</div>
                    <div class="text-value">
                        <div v-if="!currentDriver" class="text-value"></div>
                        <span v-else-if="currentDriver.FRPictures"> <i class="el-icon-check state-check"></i></span>
                        <span v-else> <i class="el-icon-close state-no-check"></i></span>
                    </div>
                </td>
                <td></td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="text-prop">{{$t('driver.driverLicense')}}</div>
                    <div v-if="!currentDriver" class="text-value"></div>
                    <div v-else class="text-value">
                        <div v-if="currentDriver.LicensePictures" class="photo-area">
                            <img :src=getImagePath(currentDriver.LicensePictures[0])>
                        </div>
                        <div v-else>{{$t('globalText.none')}}</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="text-prop">{{$t('driver.driverPhoto')}}</div>
                    <div v-if="!currentDriver" class="text-value"></div>
                    <div v-else class="text-value">
                        <div v-if="currentDriver.OtherPictures" class="driver-photo-area"><img
                                :src=getImagePath(currentDriver.OtherPictures[0])></div>
                        <div v-else>{{$t('globalText.none')}}</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="text-prop">{{$t('driver.permittedVehicles')}}</div>
                    <div v-if="!currentDriver" class="text-value"></div>
                    <div v-else-if="currentDriver.Vehicles" class="text-value">
                        <v-vehicle-avatar v-for="item in currentDriver.Vehicles" v-bind:key="item.Id" :vehicle="item">
                        </v-vehicle-avatar>
                    </div>
                    <div v-else class="text-value">{{$t('globalText.none')}}</div>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="text-prop">{{$t('driver.comment')}}</div>
                    <div class="text-value">{{currentDriver ? (currentDriver.Remark ? currentDriver.Remark : $t('globalText.none')):
                        ""}}
                    </div>
                </td>
            </tr>

        </table>
        <table class="management-table">
            <thead>
            <td colspan="2" class="management-content-title">{{$t('driver.mobile360APPDriverLoginInformation')}}
            </td>
            </thead>
            <tr>
                <td>
                    <div class="text-prop">{{$t('driver.loginID')}}</div>
                    <div v-if="!currentDriver" class="text-value"></div>
                    <div v-else="currentDriver.LoginName" class="text-value">{{currentDriver.LoginName}}</div>
                </td>
                <td>
                    <div class="text-prop">{{$t('driver.password')}}</div>
                    <div v-if="!currentDriver" class="text-value"></div>
                    <div v-else="password" class="text-value">{{password}}</div>
                </td>
            </tr>

        </table>
        <div class="button-row">
            <el-button class="ok-button" size="small" @click="OnOK">{{$t('globalText.oK')}}</el-button>
            <el-button class="delete-button" size="small" @click="OnDelete" :loading="isDeleting">{{$t('globalText.delete')}}</el-button>
        </div>
    </div>
</template>

<script>
    import vVehicleAvatar from "../../../components/management/VehicleAvatar"
    import Sort from "@/assets/js/Sort";

    export default {
        name: "ViewDriver",
        components: {
            vVehicleAvatar
        },
        data() {
            return {
                currentDriver: null,
                isDeleting: false,
            }

        },
        computed: {
            password() {
                return this.$store.getters.driverPassword;
            }

        },
        watch: {
            '$route'() {
                this.currentDriver = null;
                console.log("id=" + this.$route.query.id);
                if (this.$route.query.id)
                    this.getDriverData();
            }

        },
        methods: {
            getImagePath(path) {
                return this.$baseURL + path;
            },
            formatDate(dateStr) {
                if (dateStr) {
                    var date = new Date(dateStr);
                    return date.format("yyyy-MM-dd");
                } else {
                    return "";
                }

            },
            OnOK() {
                this.goBack();
            },
            OnDelete() {
                var deleteIds = [];
                deleteIds.push(this.currentDriver.Id);
                this.$customConfirm(this.$t('globalText.confirmDeletion'), {
                    type: 'warning'
                }).then(() => {
                    this.isDeleting = true;
                    this.$serverApi.driver.deleteDriver(deleteIds, () => {
                        this.$message(
                            {
                                message: this.$t('globalText.deletedSuccessfully'),
                                type: 'success'
                            }
                        )
                        this.goBack();
                        this.isDeleting = false;
                    }, () => {
                        this.isDeleting = false;
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'cancel delete'
                    // });
                });

            },
            goBack() {
                this.$router.go(-1);
            },
            sortVehicle(vehicles) {
                return vehicles.sort((itemA, itemB) => {
                    return Sort.NameSort(itemA.Name, itemB.Name)
                });
            },
            getDriverData() {
                var id = this.$route.query.id;
                this.$serverApi.driver.getDriveData(id, (data) => {
                    this.currentDriver = data;
                    if (this.currentDriver.Vehicles)
                        this.currentDriver.Vehicles = this.sortVehicle(this.currentDriver.Vehicles);
                })
            }
        },
        created() {
            this.getDriverData();
        }
    }
</script>

<style lang="stylus" scoped>
    @import "../../../assets/css/management.styl";

    .management-table .text-prop {
        width 170px
    }

    .management-table .text-prop-max-width {
        width 200px
    }

    .management-table .text-value {
        padding 5px
    }

    .text-max-width {
        max-width calc(100% - 180px)
    }

    .icon-streeing-whell {
        font-size: 54px;
        color: $color_black_first;
    }

    .driver-photo-area {
        margin: 10px 0;
        height: 120px;
        width: 120px;
        line-height: 14px;
        text-align: center;

    }

    .driver-photo-area img {
        vertical-align: middle;
        max-height: 120px;
    }

    .driver-id {
        color: $color_black_third
    }


</style>
