<template>
    <div class="vehicle-avatar-area" :title="title">
        <span class="via-car icon-streeing-whell"
              :style="{color:vehicle.Color ?vehicle.Color:vehicle.Fleet? vehicle.Fleet.Color:'#909090'}"
              @click="detailInfo"></span>
        <div v-if="isShowDelete" class="delete-icon">
            <span class="el-icon-remove" @click="deleteVehicle(vehicle.Id)"></span>
        </div>
        <div v-if="vehicle.HasFeature" class="face-id-icon" ><span class="via-faceID"></span></div>
        <div class="vehicle-name" @click="detailInfo">
            <div class="text-overflow-ellipsis">{{vehicle.Name}}</div>
            <div class="text-overflow-ellipsis vehicle-id">{{vehicle.Id}}</div>
        </div>


    </div>

</template>

<script>
    export default {
        name: "VehicleAvatar",
        props: {
            vehicle: {
                type: Object,
                default: null
            },
            isShowDelete: {
                type: Boolean, default: false
            },
        },
        computed: {
            title() {
                if (this.vehicle.Fleet)
                    return this.$t('way2Call.fleet') + ": " + this.vehicle.Fleet.Name + "\n" + this.$t('way2Call.vehicle') + ": " + this.vehicle.Name;
                else if (this.vehicle.FleetName)
                    return this.$t('way2Call.fleet') + ": " + this.vehicle.FleetName + "\n" + this.$t('way2Call.vehicle') + ": " + this.vehicle.Name;
                else
                    return this.$t('way2Call.fleet') + ": " + this.$t('globalText.unassigned') + "\n" + this.$t('way2Call.vehicle') + ": "+this.vehicle.Name;

            }
        },
        methods: {
            deleteVehicle(id) {
                this.$emit("deleteVehicle", id);
            },
            detailInfo(){
                this.$router.push({name: "viewVehicle", query: {id: this.vehicle.Id}}).catch();
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .vehicle-avatar-area {
        position: relative;
        width: 100px;
        line-height: 14px;
        text-align: center;
        display: inline-block;
        float: left;
        text-align: center;
        margin: 15px 20px 15px 0;

    }

    .vehicle-avatar-area .vehicle-name {
        width: 100px;
        line-height: 20px;
        margin-top 5px
        cursor pointer
    }

    .vehicle-avatar-area .vehicle-id {
        color: $color_black_third

    }

    .vehicle-avatar-area .delete-icon {
        position: absolute;
        top: 40px;
        left: 74px;
        font-size: 16px;
        color:$color_black_third
        cursor: pointer;
    }

    .vehicle-avatar-area .delete-icon:hover {
        color: $color_blue_second;
    }

    .vehicle-avatar-area .face-id-icon {
        position: absolute;
        top: -10px;
        right: 10px;
        font-size: 20px;

    }

    .icon-streeing-whell {
        font-size: 50px;
        color: $color_black_third;
        cursor pointer
    }
</style>
