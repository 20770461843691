import { render, staticRenderFns } from "./VehicleAvatar.vue?vue&type=template&id=1442405d&scoped=true&"
import script from "./VehicleAvatar.vue?vue&type=script&lang=js&"
export * from "./VehicleAvatar.vue?vue&type=script&lang=js&"
import style0 from "./VehicleAvatar.vue?vue&type=style&index=0&id=1442405d&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1442405d",
  null
  
)

export default component.exports